import React, { useState } from 'react'
import {
  Text,
  Heading,
  Container,
  Stack,
  Divider,
  // ListItem,
  // OrderedList,
  // UnorderedList,
  Button,
  Link,
} from '@chakra-ui/react'
import faker from 'faker'
import { graphql } from 'gatsby'
import BaseLayout from '../layout/base'
import {
  Heading1,
  Heading2,
  Heading3,
  // Heading4,
  // Heading5,
  // Heading6,
  Paragraph,
} from '../components/ContentWithStyles/TransformToChakra'
import SEO from '../components/SEO/PageSEO'

const Page = ({ location, data }) => {
  const [areaCode, setAreaCode] = useState('+34')
  const [phoneNumber, setPhoneNumber] = useState('(408) 102-1436')
  // useEffect(() => {
  //   setWord(generatePassword())
  // }, [])

  return (
    <BaseLayout>
      <SEO
        title="Generador de números de teléfono aleatorios"
        titleSeo="Generador de números de teléfono aleatorios"
        description="Herramienta para generar números de teléfono al azar en español."
        image={data.file.childImageSharp.gatsbyImageData.images.fallback.src}
        // lang="es"
        datePublished="2021-05-11T12:29:33-03:00"
        dateModified="2021-05-11T12:29:33-03:00"
        // breadcrumbs={[{ name: 'Vegetales', url: '/vegetales/' }]}
        permalink={location.pathname}
        // breadcrumbList={[]}
        // noindex={false}
      />
      <Container maxW="5xl">
        <Stack
          textAlign="center"
          align="center"
          spacing={{ base: 8, md: 10 }}
          py={{ base: 20, md: 28 }}
        >
          <Heading
            fontWeight={600}
            fontSize={{ base: '3xl', sm: '4xl', md: '6xl' }}
            lineHeight="110%"
            as="span"
          >
            <Text as="span" color="orange.400">
              {areaCode}
            </Text>{' '}
            {phoneNumber}
          </Heading>
          <Heading as="h2" color="gray.500" size="md" fontWeight={500}>
            Generador de números de teléfono aleatorios
          </Heading>

          <Stack spacing={2} direction="row">
            <Button
              rounded="full"
              px={6}
              colorScheme="orange"
              bg="orange.400"
              _hover={{ bg: 'orange.500' }}
              onClick={() => {
                setAreaCode(faker.phone.phoneNumber('+##'))
                setPhoneNumber(faker.phone.phoneNumber('(###) ###-####'))
              }}
            >
              Generar
            </Button>
          </Stack>
        </Stack>
      </Container>
      <Divider />
      <Container maxW="5xl" my={10}>
        <Heading1>
          Generador de n&uacute;meros de tel&eacute;fono aleatorios
        </Heading1>
        <Paragraph>
          Sin importar para qu&eacute; tarea lo necesites, este generador de
          n&uacute;mero de tel&eacute;fono aleatorios te proveer&aacute; de
          tantos n&uacute;meros de tel&eacute;fono falsos como requieras.
          Simplemente con darle al bot&oacute;n podr&aacute;s obtener un
          n&uacute;mero generado aleatoriamente siguiendo el formato
          internacional para n&uacute;meros de tel&eacute;fono, con el
          c&oacute;digo de pa&iacute;s y el c&oacute;digo de &aacute;rea,
          adem&aacute;s del n&uacute;mero particular en s&iacute; mismo.
        </Paragraph>
        <Paragraph>
          Esta herramienta online te permitir&aacute; generar n&uacute;meros de
          tel&eacute;fono al azar que podr&aacute;s usar a modo de ejemplo, para
          rellenar formularios con informaci&oacute;n de prueba, para realizar
          dise&ntilde;os e interfaces de usuario con datos ficticios, hacer
          tests en entornos de desarrollo, hacer listas de n&uacute;meros falsos
          que parezcan reales, y mucho m&aacute;s.
        </Paragraph>
        <Paragraph>
          Aqu&iacute; no se provee ninguna funcionalidad adicional, por lo que
          no podr&aacute;s utilizar estos n&uacute;meros de tel&eacute;fono
          temporales para recibir o hacer llamadas ni enviar o recibir mensajes
          de texto (SMS), dado que simplemente cumplen la funci&oacute;n de ser
          datos ficticios que pueden ser utilizados a modo de relleno.
        </Paragraph>
        <Heading2>
          N&uacute;meros de tel&eacute;fonos falsos al azar con c&oacute;digo de
          &aacute;rea y de pa&iacute;s
        </Heading2>
        <Paragraph>
          Cuando procedas a generar n&uacute;meros de tel&eacute;fonos
          aleatorios te encontrar&aacute;s con datos ficticios que siguen la
          convenci&oacute;n acerca de c&oacute;mo se compone un n&uacute;mero de
          tel&eacute;fono completo:
        </Paragraph>
        <Paragraph>
          C&oacute;digo de pa&iacute;s + C&oacute;digo de &aacute;rea +
          N&uacute;mero de tel&eacute;fono
        </Paragraph>
        <Paragraph>
          As&iacute; podr&aacute;s completar formularios y campos de texto con
          n&uacute;meros que sean aceptados por tener un formato v&aacute;lido,
          pero deber&aacute;s recordar siempre que son tel&eacute;fonos
          aleatorios que no tienen un uso pr&aacute;ctico en t&eacute;rminos de
          utilizar las redes telef&oacute;nicas para comunicarse mediante
          mensajes de texto y llamadas, por lo que no deber&iacute;as introducir
          los tel&eacute;fonos generador al azar en servicios que requieran de
          un n&uacute;mero real y en funcionamiento, o para hacer verificaciones
          de cuentas en sitios web que soliciten poder recibir un SMS o llamada
          de voz.
        </Paragraph>
        <Paragraph>
          En cuanto al formato de los n&uacute;meros de tel&eacute;fono
          aleatorios, la primera parte, el c&oacute;digo del pa&iacute;s, no
          resulta necesaria en la pr&aacute;ctica cuando la comunicaci&oacute;n
          se hace entre n&uacute;meros pertenecientes al mismo pa&iacute;s, ya
          que por defecto la red intentar&aacute; comunicar a la persona con el
          n&uacute;mero en el mismo pa&iacute;s, de no haber especificado el
          c&oacute;digo correspondiente.
        </Paragraph>
        <Paragraph>
          Pero dado que en internet los usuarios suelen provenir de todas partes
          del mundo, lo com&uacute;n es encontrarse con formularios que
          requieran introducir el c&oacute;digo de pa&iacute;s de forma
          obligatoria, adem&aacute;s de ser un est&aacute;ndar aceptado y que
          funciona igualmente a nivel local o internacional. El c&oacute;digo de
          pa&iacute;s nunca molestar&aacute; por estar, pero s&iacute; puede
          causar problemas su ausencia.
        </Paragraph>
        <Paragraph>
          En cuanto al c&oacute;digo de &aacute;rea, es siempre obligatorio y
          hace referencia a un &aacute;rea determinada dentro del pa&iacute;s al
          que corresponde al n&uacute;mero, y ser&aacute; necesario incluirlo
          siempre sin importar la naturaleza de la comunicaci&oacute;n, da igual
          que sea local o internacional, corta o larga distancia.
        </Paragraph>
        <Heading2>
          No son n&uacute;meros reales, no sirven para verificar cuentas ni
          recibir SMS o llamadas
        </Heading2>
        <Paragraph>
          <strong>
            El objetivo de estos n&uacute;meros de tel&eacute;fonos
            v&aacute;lidos es servir como datos ficticios
          </strong>{' '}
          para actividades que simplemente requieran una colecci&oacute;n de
          caracteres en el formato correcto, pero en ning&uacute;n momento se
          busca proveer un servicio para recibir o enviar SMS, ni hacer o
          responder a llamadas de ning&uacute;n tipo, al igual que tampoco
          ser&aacute;n v&aacute;lidos para aplicaciones como WhatsApp o
          Telegram, ni te permitir&aacute;n verificar tu cuenta en servicios
          como Google, YouTube, Gmail, Facebook, Instagram, Tik Tok, Twitter u
          otros.
        </Paragraph>
        <Paragraph>
          Esto es importante aclararlo ya que no son pocas las personas que
          llegan a este generador de n&uacute;meros de tel&eacute;fonos
          aleatorios intentando dar con n&uacute;meros en funcionamiento que
          permitan recibir SMS o llamadas para proceder con los pasos de
          verificaci&oacute;n o autenticaci&oacute;n requeridos por muchos
          servicios y aplicaciones.
        </Paragraph>
        <Paragraph>
          Pedir el n&uacute;mero de tel&eacute;fono del usuario es hoy en
          d&iacute;a una pr&aacute;ctica muy com&uacute;n y extendida, por lo
          general a fines de aumentar la seguridad de la cuenta del usuario y
          evitar el acceso de terceros, pero tambi&eacute;n es muy frecuente que
          sea utilizada como una medida para detener <em>bots</em> y{' '}
          <em>spammers</em> que intentan abusar de un servicio y crear una gran
          cantidad de cuentas.
        </Paragraph>
        <Paragraph>
          Sin importar si est&aacute;s intentando crear una cuenta y verificar
          tu n&uacute;mero de tel&eacute;fono, o simplemente agregar un segundo
          factor de autenticaci&oacute;n para utilizar tu tel&eacute;fono como
          paso extra en el inicio de sesi&oacute;n para incrementar la seguridad
          de tu cuenta, este generador de n&uacute;meros de tel&eacute;fonos
          ficticios no te ser&aacute; de utilidad ya que solo genera{' '}
          <em>dummy data</em> que no provee ninguna funci&oacute;n m&aacute;s
          all&aacute; de poder ser utilizada como relleno.
        </Paragraph>
        <Heading3>
          Dato curioso: &iquest;celular, m&oacute;vil o tel&eacute;fono?
        </Heading3>
        <Paragraph>
          A modo de dato extra, &iquest;Sab&iacute;as que al dispositivo como
          tal se lo conoce con diferentes nombres seg&uacute;n el pa&iacute;s
          hispanohablante en el que se encuentre la persona? Es un tema un tanto
          ya conocido, por lo que podr&aacute;s encontrar (
          <Link
            color="orange.400"
            href="https://forum.wordreference.com/threads/m%C3%B3vil-celular.1398945/"
            target="_blank"
            rel="noreferrer noopener"
          >
            discusiones al respecto a lo largo de internet
          </Link>
          ).
        </Paragraph>
        <Paragraph>
          En l&iacute;neas generales, un tel&eacute;fono a d&iacute;a de hoy es
          com&uacute;nmente un dispositivo smartphone, aunque tambi&eacute;n
          podr&iacute;a tratarse de un tel&eacute;fono fijo, que poco a poco ha
          ido decayendo en popularidad. Justamente por la existencia de estos
          dos tipos de tel&eacute;fonos dr&aacute;sticamente distintos, es que a
          tel&eacute;fono m&oacute;vil se lo suele llamar simplemente
          &ldquo;m&oacute;vil&rdquo; en Espa&ntilde;a y &ldquo;celular&rdquo; (o
          &ldquo;celu&rdquo; para acortar, de forma coloquial) en
          Latinoam&eacute;rica.
        </Paragraph>
        <Paragraph>
          Esto es simplemente una curiosidad y no impedir&aacute; a nadie
          generar n&uacute;meros de celular falsos con este generador de
          n&uacute;meros m&oacute;viles aleatorios, porque sin importar de
          qu&eacute; tipo de tel&eacute;fono se trate, m&oacute;vil/celular o
          fijo, el formato del n&uacute;mero de tel&eacute;fono seguir&aacute;
          el mismo formato y funcionar&aacute; de la misma forma para completar
          cualquier campo de texto o planilla.
        </Paragraph>
        <Paragraph>
          En conclusi&oacute;n, no importa si lo que necesitas es un generador
          de n&uacute;meros de celular falsos, un generador de n&uacute;meros de
          m&oacute;vil al azar o una lista de n&uacute;meros de tel&eacute;fono
          fijos aleatorios, porque todos ellos seguir&aacute;n el mismo formato
          y podr&aacute;n ser obtenidos utilizando esta herramienta online que
          te proveer&aacute; con cuantos n&uacute;meros como necesites, todo
          ellos al alcance de un bot&oacute;n y generados en un instante.
        </Paragraph>
        <Paragraph>
          Por &uacute;ltimo, simplemente volver a insistir en que esta utilidad
          solo busca proveer de una forma f&aacute;cil y r&aacute;pida de
          generar datos ficticios al azar para completar formularios y
          planillas, sin ninguna funcionalidad relacionada a la
          comunicaci&oacute;n, por lo que se trata de un servicio de
          generaci&oacute;n de n&uacute;meros de tel&eacute;fonos al azar, no de
          un servicio para recibir SMS o llamadas.
        </Paragraph>
        <Paragraph>
          Los datos aleatorios aqu&iacute; obtenidos deben ser utilizados dentro
          del marco legal y siempre en contexto donde no se requiera un
          tel&eacute;fono funcional, dejando en claro que su uso es
          &uacute;nicamente a modo de ejemplo o relleno, no intentando confundir
          o enga&ntilde;ar a ning&uacute;n usuario o sistema. Este generador de
          tel&eacute;fonos aleatorios no es m&aacute;s que una simpe herramienta
          para ahorrar tiempo al completar datos y agilizar los procesos de los
          que forman parte estas tareas.
        </Paragraph>
      </Container>
    </BaseLayout>
  )
}

export default Page

export const pageQuery = graphql`
  query {
    file(relativePath: { regex: "/extreme-5497194_1920-photopea.png/" }) {
      childImageSharp {
        gatsbyImageData(quality: 40, width: 800)
      }
    }
  }
`
